@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");
.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Nunito", sans-serif;
  background-image: linear-gradient(90deg, #410076, #00e6f7);
}
body {
  padding: 0;
  margin: 0;
}